<template>
    <a-card>
        <a-page-header
            title='任务预算和KPI详情'
        />
        <div class="row">任务名称：{{ dataForm.missionName || '-' }}</div>
        <div class="row">任务关联广告主：
            <span v-for="(item, index) in (dataForm.missionAdvertiserRelationList || [])" :key="item.advertiserId">
                {{ index != 0 ? '、' : '' }}{{ item.advertiserName }}
            </span>
        </div>
        <div class="row">
            <a-space>
                <div>任务总预算：</div>
                <div><a-input-number v-model="dataForm.totalBudget" :min="0" :precision="0" class="width-150p" /></div>
                <div>元</div>
                <div>{{ formatUnit(dataForm.totalBudget) }}</div>
            </a-space>
        </div>
        <div class="row">
            <a-space>
                <div>任务日预算：</div>
                <div><a-input-number v-model="dataForm.dailyBudget" :min="0" :precision="0" class="width-150p" /></div>
                <div>元</div>
                <div>{{ formatUnit(dataForm.dailyBudget) }}</div>
            </a-space>
        </div>
        <div class="row">任务总KPI：{{ totalKpi || '-' }}</div>
        <div class="row">
            <div class="table-box">
                <div class="header-box">
                    <div class="box">
                        <div class="col">品牌</div>
                        <div class="col">车系</div>
                        <div class="col">KPI</div>
                        <div class="col">KPI完成数</div>
                        <div class="col">日预算（元）</div>
                        <div class="col"><div>本日累计消耗（元）<br><span>PS：关联广告消费之和</span></div></div>
                        <div class="col">操作</div>
                    </div>
                </div>
                <div class="body-box">
                    <div class="box" v-for="item in dataForm.carSeriesRelationList" :key="item.id">
                        <div class="col">{{ item.principalName || '-' }}</div>
                        <div class="col">{{ item.carSeriesName || '-' }}</div>
                        <div class="col"><a-input-number v-model="item.detailKpi" :min="0" :precision="0" class="width-150p" @change="onChangeInput" /></div>
                        <div class="col">{{ item.kpiAchievedCount || '-' }}</div>
                        <div class="col"><a-input-number v-model="item.dailyBudget" :min="300" :precision="0" class="width-150p" /></div>
                        <div class="col">{{ item.todayTotalCost || '-' }}</div>
                        <div class="col">
                            <base-button
                                :type="'link'"
                                :title="'计划'"
                                @onClickBtn="jumpPlanPage(dataForm.missionCode, item)"
                            ></base-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <a-space>
                <base-button
                    :title="'返回'"
                    @onClickBtn="handleBack"
                ></base-button>
                <base-button
                    :type="'primary'"
                    :title="'保存'"
                    :loading="isSubmit"
                    :disabled="isSubmit"
                    @onClickBtn="handleSubmit"
                ></base-button>
            </a-space>
        </div>
    </a-card>
</template>

<script>
import _ from 'lodash'

export default {
    data() {
        this.onChangeInput = _.debounce(this.onChangeInput, 1000)

        return {
            id: this.$route.query.id,
            layout: {
                labelCol: { span: 6 },
                wrapperCol: { span: 11 }
            },
            dataForm: {},
            totalKpi: '',
            rules: {
            },
            isSubmit: false,
        }
    },
    created() {
        this.getDetail()
    },
    methods: {
        // 品牌车系 - 任务明细KPI计算任务总KPI
        onChangeInput() {
            let totalVal = 0
            this.dataForm.carSeriesRelationList.map(item => {
                totalVal += Number(item.detailKpi)
            })
            this.totalKpi = totalVal || ''
        },
        //获取详情
        getDetail() {
            this.$api.core.deliverManage.findBudgetAndKpiDetailByMissionId(this.id).then((res) => {
                if(res.code == 200) {
                    this.$set(this, 'dataForm', res.data)
                    this.onChangeInput()
                } else {
                    this.$message.error(`获取详情失败，${res.message}`)
                }
            })
        },
        // 单位转化为万，保留4位数
        formatUnit(value = 0) {
            let val = Number(value)
            return (val/1e4).toFixed(4) + '万'
        },
        jumpPlanPage(missionCode, item) {
            this.$router.push({
                path: '/advertisingPlanManage',
                query: {
                    missionCode,
                    principalId: item.principalId,
                    carSeriesId: item.carSeriesId,
                }
            })
        },
        //取消
        handleBack() {
            let that = this
            this.$confirm({
                content: h => <div><span style="color: red; display: block;">表单数据还未保存</span>离开数据将会丢失，是否确认离开？
                </div>,
                okText: '确定',
                cancelText: '取消',
                onOk () {
                    that.$router.push({
                        path: '/taskSchedule/index'
                    })
                }
            })
        },
        //提交
        handleSubmit() {
            this.isSubmit = true
            this.$api.core.deliverManage.updateBudgetAndKpi( this.dataForm ).then(res => {
                this.isSubmit = false
                if(res.code == 200) {
                    this.$message.success(`提交成功`)
                } else {
                    this.$message.error(`提交失败，${res.message}`)
                }
            })
        },
    }
}
</script>

<style scoped lang="less">
/deep/ .ant-page-header {
    padding-left: 0;
    padding-right: 0;
}
</style>

<style scoped lang="less">
/deep/ .ant-page-header {
    padding-left: 0;
    padding-right: 0;
}
.row {
    margin: 20px 0;
}
.width-150p {
    width: 150px;
}
.table-box {
    width: 100%;
    border: 1px solid #e3e3e3;
    .header-box {
        background-color: #eee;
    }
    .box {
        display: flex;
        & + .box {
            border-top: 1px solid #e3e3e3;
        }
    }
    .col {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px 10px;
        width: 14%;
        min-height: 44px;
        & + .col {
            border-left: 1px solid #e3e3e3;
        }
        span {
            font-size: 12px;
        }
    }
}
</style>